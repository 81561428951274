import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import ProductParamsTable from './productDetails/ProductParamsTable';
import ProductSpecialParamsTable from './productDetails/ProductSpecialParamsTable';
import ProductComponentsTable from './productDetails/ProductComponentsTable';
import AttachmentsForDealerAndUser from '../project/AttachmentsForDealerAndUser';
import ProductPricesTable from './productDetails/ProductPricesTable';
import Packaging from './productDetails/Packaging';
import PriceAndAddCart from './productDetails/PriceAndAddCart';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {getQueryParamNumber, navigateToParametrized} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import {RIGHTS} from '../../constants/Rights';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ProductDetail, Attachments, ProductRelatives, Rights} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { formatQuantity, formatProductName, formatUnitPrice } from '../../lib/formatting';
import RelatedProducts from '../project/RelatedProducts';
import ProductDetailsActions from './productDetails/ProductDetailsActions';
import ProductImage from './productDetails/ProductImage';
import CustomerProductCodeWithEditation from '../project/CustomerProductCodeWithEditation';
import ProductAvailability from '../project/productTable/ProductAvailability';
import Image from '../general/Image';
import PrintProductLabel from './PrintProductLabel';
import InputText from '../general/InputText';
import ButtonFetch from '../fetch/ButtonFetch';
import generalFailedPC from '../fetch/generalFailedPC';
import { createFetchProductEditBarcode, createFetchProductEditCustomerCode } from '../../backend/apiCalls';
import { Carousel, Icon } from 'antd';
import { merge } from '../../lib/object';
import UnitPrice from '../general/UnitPrice';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';

/**
 * @fero
 */

class ProductDetailsPage extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        [GLOBAL_DATA.PRODUCT_DETAILS]: ProductDetail.isRequired,
        [GLOBAL_DATA.PRODUCT_RELATIVES]: ProductRelatives.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            newBarcode: null,
            stockId: null,
        };
    }

    onNewBarcodeChange = (code) => {
        this.setState({newBarcode: code});
    };

    onStockChange = (newStockId) => {
        this.setState({stockId: newStockId});
    };

    onVariantChange = (variant) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.ID_VARIANT]: variant != null ? variant.id_variant : null});
    };

    render() {
        const {
            [GLOBAL_DATA.PRODUCT_DETAILS]: productDetails,
            [GLOBAL_DATA.PRODUCT_RELATIVES]: productRelatives,
            [GLOBAL_DATA.RELOAD_DATA]: reload,
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            location
        } = this.props;
        const {newBarcode, stockId} = this.state;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const variantId = getQueryParamNumber(location, QUERY_PARAMS.ID_VARIANT);
        const variants = productDetails.variants != null && Array.isArray(productDetails.variants) && productDetails.variants.length > 0 ?
            productDetails.variants : null;
        const selectedVariant = variants != null ? 
            (variantId != null ? 
                variants.find(v => v.id_variant == variantId) 
                : (rights < RIGHTS.MARKETING ? variants[0] : null)
            ) : null;
        const productData = selectedVariant != null ? merge(selectedVariant, {id: selectedVariant.id_variant, tax_rate: productDetails.tax_rate}) : productDetails;
        const thumbnail = productData.thumbnail != null ? productData.thumbnail : productDetails.thumbnail;

        const barcodes = productData.barcodes != null ? productData.barcodes.map(code => {
            return {
                label: <span>
                    <Trans>Čiarový kód</Trans>
                    {code.notice != null && code.notice != '' ? ' (' + code.notice + ')' : ''}
                </span>, 
                value: <div className="d-flex align-items-center">
                    {code.code}
                    <PrintProductLabel
                        productId={productData.id}
                        productLabel={formatProductName(productData.designation, productData.manufacturer)}
                        customerId={customerId}
                        barcode={code.code}
                        size="small"
                        className="ml-2"
                    />
                </div>
            };
        }) : [];
        
        return <React.Fragment>
            <Helmet
                title={`${productDetails.designation} (${productDetails.manufacturer})`}
                description={`${productDetails.designation} (${productDetails.manufacturer}) ${productDetails.description_short}`}
            />
            <div className="full-size-height overflow-auto">
                <ProductDetailsActions
                    productDetails={productData}
                    isVariant={selectedVariant != null}
                    customerId={customerId}
                    reload={() => {
                        reload([GLOBAL_DATA.PRODUCT_DETAILS])
                    }}
                />
                <h1 className="text-center">
                    {formatProductName(productData.designation, productData.manufacturer)}
                </h1>
                <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER} deny={[RIGHTS.MARKETING, RIGHTS.WORKER]}>
                    <div className="d-flex justify-content-center align-items-center py-3">
                        <span className="px-2 text-secondary font-weight-bold"><Trans>Vlastné ozn.:</Trans></span>
                        <CustomerProductCodeWithEditation
                            reload={() => {
                                reload([GLOBAL_DATA.PRODUCT_DETAILS])
                            }}
                            productId={productData.id}
                            customerId={customerId}
                            code={productData.customer_code}
                        />
                        { productData.customer_code != null ? <PrintProductLabel
                            productId={productData.id}
                            productLabel={formatProductName(productData.designation, productData.manufacturer)}
                            customerId={customerId}
                            size="small"
                            className="ml-2"
                        /> : null }
                    </div>
                </RightsWrapper>
                <div className="d-flex justify-content-around flex-wrap">
                    <div className="product-detail-col p-3">
                        <Carousel
                            autoplay={true}
                            arrows={true}
                        >
                            <ProductImage src={thumbnail} key={"thumb"}/>
                            { productData.images != null ? productData.images.map((img, idx) => {
                                return <ProductImage src={img.filename} key={idx}/>
                            }) : null}
                        </Carousel>
                        {productData.manufacturer_logo != null ?
                            <Image
                                src={productData.manufacturer_logo}
                                wrapperClassName="pt-3"
                                className="product-detail-logo"
                            /> : null
                        }
                    </div>
                    <div className="product-detail-col p-3">
                        <div className="mt-3 mb-4">
                            {productData.description_short}
                        </div>
                        <div className="mt-3">
                            <ProductSpecialParamsTable
                                lines={[
                                    {
                                        label: <Trans>Objednávacie č.</Trans>,
                                        value: productData.order_code
                                    },
                                    {
                                        label: <Trans>Brutto cena</Trans>,
                                        value: <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                                            <UnitPrice price={productData.list_price} units={productData.quantity_units}/>
                                        </RightsWrapper>
                                    },
                                    {
                                        label: <Trans>Hmotnosť brutto</Trans>,
                                        value: productData.mass != null ? `${productData.mass} kg` : null
                                    },
                                    {
                                        label: <Trans>Skladová plocha</Trans>,
                                        value: <RightsWrapper from={RIGHTS.MARKETING}>
                                            {productData.shelf_size}
                                        </RightsWrapper>
                                    },
                                    {
                                        label: <Trans>INTRASTAT</Trans>,
                                        value: productDetails.intrastat
                                    },
                                    ...barcodes
                                ]}
                            />
                        </div>
                        <RightsWrapper from={RIGHTS.WORKER}>
                            <ProductSpecialParamsTable
                                lines={[
                                    {
                                        label: <Trans>Nový čiarový kód</Trans>, 
                                        value: <RightsWrapper from={RIGHTS.WORKER}>
                                            <div className="d-flex align-items-center">
                                                <InputText
                                                    value={newBarcode}
                                                    onChange={this.onNewBarcodeChange}
                                                    size="small"
                                                    allowClear={true}
                                                />
                                                <ButtonFetch
                                                    type="primary"
                                                    size="small"
                                                    values={{}}
                                                    disabled={newBarcode == null}
                                                    Failed={generalFailedPC(t`Nepodarilo pridať čiarový kód.`)}
                                                    fetchFunction={createFetchProductEditBarcode({id_product: productData.id, code: newBarcode})}
                                                    onFinish={() => {
                                                        reload([GLOBAL_DATA.PRODUCT_DETAILS])
                                                    }}
                                                >
                                                    <Icon type="save"/>
                                                </ButtonFetch>
                                            </div>
                                        </RightsWrapper>
                                    }
                                ]}
                            />
                        </RightsWrapper>
                        {productData && productData.params && productData.params.length > 0 ?
                            <ProductParamsTable
                                productParams={productData.params}
                            />
                            : null
                        }
                        {productData && productData.components && productData.components.length > 0 ?
                            <div className="my-3">
                                <ProductComponentsTable
                                    components={productData.components}
                                />
                            </div>
                            : null
                        }
                    </div>
                    <div className="product-detail-col p-3">
                        <div className="my-3">
                            <ProductPricesTable
                                productDetails={productData}
                            />
                        </div>
                        <div className="my-3">
                            <Packaging
                                productDetails={productData}
                            />
                        </div>
                        <div className="my-3">
                            <span className="mr-2 text-secondary font-weight-bold"><Trans>Násobnosť:</Trans></span>
                            {formatQuantity(productData.quantity_div, productData.quantity_units)}
                        </div>
                        <div className="my-3 d-flex align-items-center">
                            <span className="mr-2 text-secondary font-weight-bold">
                                <Trans>Dostupnosť:</Trans>
                            </span>
                            <ProductAvailability
                                productId={productData.id}
                                productDesignation={productData.designation}
                                productManufacturer={productData.manufacturer}
                                quantityAvailable={productData.quantity_available}
                                quantityOrdered={productData.quantity_ordered}
                                quantityOnStock={productData.quantity_on_stock}
                                quantityUnits={productData.quantity_units}
                                stockOptions={productData.stock_options}
                                stockId={stockId}
                                onStockChange={this.onStockChange}
                            />
                        </div>
                        <div className="my-3">
                            <PriceAndAddCart
                                productId={productDetails.id}
                                productLabel={formatProductName(productDetails.designation, productDetails.manufacturer)}
                                productData={productData}
                                stockId={stockId}
                                variants={variants}
                                onVariantChange={this.onVariantChange}
                            />
                        </div>
                    </div>
                    {productData.description_html != null && productData.description_html != '' ?
                        <div className="product-detail-html-description p-3">
                            <div
                                dangerouslySetInnerHTML={{__html: productData.description_html}}
                            />
                        </div> :
                        null
                    }
                    <div className="product-detail-col p-3">
                        <AttachmentsForDealerAndUser
                            productId={productDetails.id}
                        />
                    </div>
                </div>
                <div className="full-size-width p-3">
                    <RelatedProducts
                        customerId={customerId}
                        productId={productDetails.id}
                        productDesignation={productDetails.designation}
                        productManufacturer={productDetails.manufacturer}
                        productRelatives={productRelatives}
                        reload={() => {
                            reload([GLOBAL_DATA.PRODUCT_RELATIVES])
                        }}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

}

export default locationHOC( 
    withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
        withDataHOC([
            GLOBAL_DATA.PRODUCT_DETAILS, 
            GLOBAL_DATA.PRODUCT_RELATIVES,
            GLOBAL_DATA.RELOAD_DATA
        ])(ProductDetailsPage)
    )
);